import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './App.css';
import {BrowserRouter as Router, Link, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Login from "./pages/Login";
import Header from "./components/Header";
import {check} from "./http/userAPI";
import {Container} from "react-bootstrap";
import Alert from "./components/Alert";
import Loader from "./components/Loader";
import {AxiosError} from "axios";
import Entities from "./pages/Entities";
import EditSound from "./pages/EditSound";
import EditPlaylist from "./pages/EditPlaylist";
import EditCategory from "./pages/EditCategory";

function App() {
    const [user, setUser] = useState(null);
    const [waitCheckAuth, setWaitCheckAuth] = useState(true);

    const [loader, setLoader] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const handleNotification = (message, type = 'info') => {
        let showMessage;
        if (message instanceof AxiosError)
            showMessage = message?.response?.data?.message || message.message;
        else
            showMessage = message;

        const newNotification = {
            id: Date.now(),
            message: showMessage,
            type,
        };
        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);

        setTimeout(() => {
            setNotifications((prevNotifications) =>
                prevNotifications.filter((item) => item.id !== newNotification.id)
            );
        }, 5000);
    };

    const checkAuth = () => {
        setLoader({status: true, message: 'Авторизация'});
        check()
            .then(setUser)
            .catch(err => handleNotification(err?.response?.data?.message || err.message, 'danger'))
            .finally(() => {
                setLoader(null);
                setWaitCheckAuth(false);
            })
    }

    useEffect(() => {
        if (localStorage.getItem('token') && user === null)
            checkAuth()
        else
            setWaitCheckAuth(false);
    }, [user]);

    return (
        <Router>
            <Header user={user}/>
            <Container>
                <Routes>
                    {
                        user !== null ?
                            <>
                                <Route
                                    path={'/:type'} element={
                                    <Entities
                                        handleNotification={handleNotification}
                                        setLoader={setLoader}
                                    />
                                }/>
                                <Route
                                    path={'/sounds/:id'} element={
                                    <EditSound
                                        handleNotification={handleNotification}
                                        setLoader={setLoader}
                                    />
                                }/>
                                <Route
                                    path={'/playlists/:id'} element={
                                    <EditPlaylist
                                        handleNotification={handleNotification}
                                        setLoader={setLoader}
                                    />
                                }/>
                                <Route
                                    path={'/categories/:id'} element={
                                    <EditCategory
                                        handleNotification={handleNotification}
                                        setLoader={setLoader}
                                    />
                                }/>
                                <Route path={'*'} element={<Navigate to={'/categories'}/>}/>
                            </> :
                            !waitCheckAuth && <>
                                <Route path="/" element={
                                    <Login setUser={setUser}
                                           handleNotification={handleNotification}
                                           setLoader={setLoader}
                                    />
                                }/>
                                <Route path={'*'} element={<Navigate to={'/'}/>}/>
                            </>
                    }
                </Routes>
            </Container>
            <Alert notifications={notifications} setNotifications={setNotifications}/>
            {loader !== null && <Loader loader={loader}/>}
        </Router>
    );
}

export default App;
