import {$authHost, $host} from "./index";

export const getAllSounds = async () =>{
    const {data} = await $authHost.get('api/sound');
    return data;
}

export const getOneSound = async (id) => {
    const {data} = await $host.get(`api/sound/${id}`);
    return data;
}

export const createSound = async (body) => {
    const {data} = await $authHost.post('api/sound', body);
    return data;
}

export const updateSound = async (id, body) => {
    const {data} = await $authHost.patch(`api/sound/${id}`, body);
    return data;
}

export const deleteSound = async (id) => {
    const {data} = await $authHost.delete(`api/sound/${id}`);
    return data;
}