import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {createPlaylist, deletePlaylist, getOnePlaylist, updatePlaylist} from "../http/playlistAPI";
import {getAllSounds} from "../http/soundAPI";
import {Button, Container, Form, Table} from "react-bootstrap";
import {IMAGES_EXT} from "../utils/consts";
import {Typeahead} from "react-bootstrap-typeahead";

const EditPlaylist = ({setLoader, handleNotification}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [playlist, setPlaylist] = useState({});
    const [dataImage, setDataImage] = useState();
    const [allSounds, setAllSounds] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);

    const getSystemData = async () => {
        if (+id !== 0) {
            try {
                setLoader({status: true, message: 'Получение плейлиста'})
                const playlist = await getOnePlaylist(id);
                setPlaylist({...playlist});
            } catch (e) {
                handleNotification(e, 'danger');
                navigate('/playlists');
            }
        } else {
            setPlaylist({
                name: '',
                isPremium: false,
                image: null,
                sounds: []
            })
        }
        setLoader({status: true, message: 'Получение шрифтов'});
        try {
            const sounds = await getAllSounds();
            setAllSounds(sounds);
        } catch (e) {
            handleNotification(e, 'danger');
            navigate('/playlists');
        }
        setLoader(null);
    }

    const onChangeFile = (event) => {
        const file = event.target.files[0];

        if (file) {
            setPlaylist({...playlist, image: file});

            const reader = new FileReader();

            reader.onloadend = () => {
                setDataImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    }

    const onSelectSound = () => {
        const name = searchQuery[0];
        const sound = allSounds.find(el => el.name === name);
        if (!sound)
            return handleNotification('Звук не найден', 'danger');
        if (playlist.sounds.includes(el => el.id === sound.id))
            return handleNotification('Звук уже добавлен', 'danger');
        else {
            setPlaylist({...playlist, sounds: [...playlist.sounds, sound]});
        }
        setSearchQuery([]);
    }

    const changePosition = (soundId, e) => {
        if (e.key !== 'Enter')
            return;

        let newPosition = +e.target.value;

        if (isNaN(newPosition) || newPosition <= 0)
            newPosition = 1;

        const currentSound = allSounds.find(el => el.id === soundId);

        if (newPosition >= playlist.sounds.length)
            setPlaylist({
                ...playlist,
                sounds: [...playlist.sounds.filter(el => el.id !== currentSound.id), currentSound]
            })
        else {
            const soundsPositions = [];
            for (let i = 0; i < playlist.sounds.length; i++) {
                if (i + 1 === newPosition)
                    soundsPositions.push(currentSound);
                if (playlist.sounds[i].id !== currentSound.id)
                    soundsPositions.push(playlist.sounds[i]);
            }

            setPlaylist({...playlist, sounds: [...soundsPositions]});
        }

        e.target.value = '';
    }

    const savePlaylist = () => {
        if (!playlist.name)
            return handleNotification('Введите имя', 'danger');

        if (!playlist.description)
            return handleNotification('Введите описание', 'danger');

        if (!+id && !playlist.image)
            return handleNotification('Выберите изображение', 'danger');

        const formData = new FormData();
        formData.append('name', playlist.name);
        formData.append('description', playlist.description);
        formData.append('isPremium', playlist.isPremium);
        formData.append('sounds', JSON.stringify(playlist.sounds));
        if (playlist.image)
            formData.append('image', playlist.image);

        setLoader({status: true, message: 'Сохранение плейлиста'});
        if (+id)
            updatePlaylist(id, formData)
                .then(() => handleNotification('Плейлист сохранен', 'success'))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => {
                    setLoader(null);
                    navigate('/playlists');
                })
        else
            createPlaylist(formData)
                .then(() => handleNotification('Плейлист сохранен', 'success'))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => {
                    setLoader(null);
                    navigate('/playlists');
                })
    }

    const deleteCurrentPlaylist = () => {
        setLoader({status: true, message: 'Удаление звука'});
        deletePlaylist(id)
            .then(()=>handleNotification('Плейлист удален', 'success'))
            .catch(err=>handleNotification(err, 'danger'))
            .finally(()=> {
                setLoader(null);
                navigate('/');
            });
    }

    useEffect(() => {
        getSystemData()
            .finally()
    }, [])

    return (
        <Container className={'mt-2'}>
            <h1>{+id ? 'Изменение' : 'Создание'} плейлиста</h1>
            <Form>
                <Form.Group className={'mt-2'}>
                    <Form.Label>Имя</Form.Label>
                    <Form.Control
                        value={playlist.name}
                        onChange={e => setPlaylist({...playlist, name: e.target.value})}
                        placeholder={'Имя'}
                    />
                </Form.Group>

                <Form.Group className={'mt-2'}>
                    <Form.Label>Описание</Form.Label>
                    <Form.Control
                        value={playlist.description}
                        onChange={e => setPlaylist({...playlist, description: e.target.value})}
                        placeholder={'Описание'}
                    />
                </Form.Group>

                <Form.Group className={'mt-2'}>
                    <Form.Label>Премиум</Form.Label>
                    <Form.Check
                        checked={playlist.isPremium}
                        onChange={e => setPlaylist({...playlist, isPremium: e.target.checked})}
                    />
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Label>Изображение</Form.Label>
                    <Form.Control type={'file'}
                                  accept={IMAGES_EXT.join(', ')}
                                  onChange={(e) => onChangeFile(e)}
                    />
                </Form.Group>
                {(dataImage || playlist.imageURL) && (
                    <img className={'mb-2'} src={dataImage || playlist.imageURL} alt="Image"
                         style={{maxWidth: '100%', maxHeight: '200px'}}/>
                )}

                <Form.Group className="mb-2">
                    <Form.Label>Звуки</Form.Label>
                    <Typeahead
                        id={'add-sounds'}
                        options={allSounds.filter(sound => !playlist.sounds.some(el => sound.id === el.id)).map(el => el.name)}
                        selected={searchQuery}
                        onChange={setSearchQuery}
                        maxResults={5}
                    />
                    <Button className={'mt-2'} onClick={onSelectSound}>Добавить звук</Button>

                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Имя</th>
                            <th>Позиция</th>
                            <th>Управление</th>
                        </tr>
                        </thead>
                        <tbody>
                        {playlist.sounds?.map((el, index) =>
                            <tr key={`${el.name}-${el.id}`}>
                                <td>{index + 1}</td>
                                <td>
                                    <Link to={`/sounds/${el.id}`} target={'_blank'}>
                                        {el.name}
                                    </Link>
                                </td>
                                <td>
                                    <Form.Control
                                        type={"number"}
                                        placeholder={`${index + 1}`}
                                        onKeyDown={(e) => changePosition(el.id, e)}
                                    />
                                </td>
                                <td>
                                    <Button
                                        onClick={() => setPlaylist({
                                            ...playlist,
                                            sounds: playlist.sounds.filter(sound => sound.id !== el.id)
                                        })}
                                    >Удалить</Button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Form.Group>
            </Form>
            <Button className={'mt-2'} onClick={savePlaylist}>Сохранить</Button><br/>
            {!!+id && <Button className={'mt-2'} onClick={deleteCurrentPlaylist}>Удалить</Button>}
        </Container>
    );
};

export default EditPlaylist;