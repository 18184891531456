import React from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

const Header = ({user}) => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    Sleep Sounds
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {!user && <Nav.Link as={Link} to="/">
                            Авторизация
                        </Nav.Link>}

                        {
                            user &&
                            <>
                                <Nav.Link as={Link} to="/categories">
                                    Категории
                                </Nav.Link>
                                <Nav.Link as={Link} to="/playlists">
                                    Плейлисты
                                </Nav.Link>
                                <Nav.Link as={Link} to="/sounds">
                                    Звуки
                                </Nav.Link>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;