import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {getAllSounds} from "../http/soundAPI";
import {getAllPlaylists} from "../http/playlistAPI";
import {getAllCategories, updateCategory, updateCategoryPosition} from "../http/categoryAPI";
import {useNavigate, useParams} from "react-router-dom";

const Entities = ({setLoader, handleNotification}) => {
    const {type} = useParams();
    const navigate = useNavigate();
    const [entities, setEntities] = useState([]);
    const [filterEntities, setFilterEntities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        getEntitiesWorker();
    }, []);

    useEffect(() => {
        getEntitiesWorker()
    }, [type]);

    useEffect(()=>{
        setFilterEntities([...entities.filter(el=>el.name.toLowerCase().includes(searchQuery.toLowerCase()))]);
    }, [searchQuery, entities]);

    const getEntitiesWorker = () => {
        setLoader({status: true, message: 'Получение данных'});
        if (type === 'sounds')
            getAllSounds()
                .then(res => setEntities([...res]))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => setLoader(null));
        else if (type === 'playlists')
            getAllPlaylists()
                .then(res => setEntities([...res]))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => setLoader(null));
        else if (type === 'categories')
            getAllCategories()
                .then(res => setEntities([...res]))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => setLoader(null));
        else
            navigate('/sounds')
    }

    const updateCategoryPositionHandler = (id, e) => {
        if (e.key !== 'Enter')
            return;

        updateCategoryPosition(id, {position: e.target.value})
            .catch(err=>handleNotification(err, 'danger'))
            .finally(()=>getEntitiesWorker())
    }

    return (
        <Container className={'mt-2'}>
            <h1>{type === 'sounds' ? 'Звуки' : type === 'playlists' ? 'Плейлисты' : 'Категории'}</h1>
            <Button
                className={'mb-2'}
                onClick={()=>navigate(`/${type}/0`)}
            >Создать</Button>
            <Form.Control
                className={'mb-2'}
                placeholder={'Введите имя для поиска'}
                value={searchQuery}
                onChange={e=>setSearchQuery(e.target.value)}
            />
            <Table striped bordered>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Имя</th>
                    <th>Дата изменения</th>
                    <th>Дата создания</th>
                    {type === 'categories' && <th>Позиция</th>}
                </tr>
                </thead>
                <tbody>
                {
                    filterEntities.map((el, index) =>
                        <tr
                            key={`${el.name}-${el.id}`}
                            onClick={()=>navigate(`/${type}/${el.id}`)}
                            style={{cursor: "pointer"}}
                        >
                            <td>{index + 1}</td>
                            <td>{el.name}</td>
                            <td>{new Date(el.updatedAt).toLocaleString()}</td>
                            <td>{new Date(el.createdAt).toLocaleString()}</td>
                            {type === 'categories' && <td>
                                <Form.Control
                                    type={'number'}
                                    placeholder={el.position+1}
                                    onClick={e=>e.stopPropagation()}
                                    onKeyDown={e=>updateCategoryPositionHandler(el.id, e)}
                                />
                            </td>}
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </Container>
    );
};

export default Entities;