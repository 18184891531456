import {$authHost, $host} from "./index";

export const getAllCategories = async () => {
    const {data} = await $host.get('api/category/');
    return data;
}

export const getOneCategory = async (id) => {
    const {data} = await $host.get(`api/category/${id}`);
    return data;
}

export const createCategory = async (body) => {
    const {data} =await $authHost.post('api/category', body);
    return data;
}

export const updateCategory = async (id, body) => {
    const {data} = await $authHost.patch(`api/category/${id}`, body);
    return data;
}

export const updateCategoryPosition = async (id, body) => {
    const {data} = await $authHost.patch(`api/category/position/${id}`, body);
    return data;
}

export const deleteCategory = async (id) => {
    const {data} = await $authHost.delete(`api/category/${id}`);
    return data;
}